import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import LineChart from '../components/LineChart';
import BarChart from '../components/BarChart';
import { useWebSocket } from '../contexts/WebSocketContext';
import { debounce } from 'lodash';
import { Helmet } from 'react-helmet-async';

function Asset() {
    const { asset, pair } = useParams();
    const { sendMessage, isConnected, getBars } = useWebSocket();
    const [historicalData, setHistoricalData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const fetchedRef = useRef(false);
    const wsInitializedRef = useRef(false);
    const [metadata, setMetadata] = useState(null);
    const [price, setPrice] = useState(null);
    const [selectedTimeframe, setSelectedTimeframe] = useState('1M');

    // Effect to handle WebSocket subscription
    useEffect(() => {
        if (!isConnected || wsInitializedRef.current) return;
        wsInitializedRef.current = true;

        const subscriptionKey = `${asset}_${pair.replace('-', '_')}`;
        const success = sendMessage({
            type: 'subscribe_bars',
            asset_id: subscriptionKey
        });

        if (!success) {
            const retryInterval = setInterval(() => {
                if (sendMessage({
                    type: 'subscribe_bars',
                    asset_id: subscriptionKey
                })) {
                    clearInterval(retryInterval);
                }
            }, 100);

            return () => clearInterval(retryInterval);
        }

        return () => {
            sendMessage({
                type: 'unsubscribe_bars',
                asset_id: subscriptionKey
            });
            wsInitializedRef.current = false;
        };
    }, [isConnected, asset, pair, sendMessage]);

    // Effect to update historical data with new bars
    useEffect(() => {
        const assetKey = `${asset}_${pair}`;
        const currentBars = getBars(assetKey);
        
        if (currentBars.length === 0) return;

        // Update price from the latest bar
        const latestBar = currentBars[currentBars.length - 1];
        setPrice(latestBar.close);

        setHistoricalData(prevData => {
            if (!prevData) return currentBars;

            // Create a map for faster lookups
            const barMap = new Map();
            prevData.forEach(bar => barMap.set(bar.time, bar));
            
            // Add or update bars from WebSocket
            currentBars.forEach(newBar => {
                barMap.set(newBar.time, newBar);
            });

            // Convert back to array and sort
            return Array.from(barMap.values())
                .sort((a, b) => a.time - b.time);
        });
    }, [asset, pair, getBars]);

    // Effect to fetch initial historical data
    useEffect(() => {
        const fetchMetadata = async () => {
            const response = await fetch(`https://prices.now/metadata/${asset}/${pair}`);
            const data = await response.json();
            setMetadata(data);
        };

        const fetchHistoricalData = async () => {
            if (fetchedRef.current) return;
            fetchedRef.current = true;
            
            try {
                setIsLoading(true);
                const response = await fetch(`https://prices.now/historical_prices/${asset}/${pair}`);
                const data = await response.json();

                const timestampMap = new Map();
                data.forEach(item => {
                    const timestamp = item[4];
                    if (!timestampMap.has(timestamp)) {
                        timestampMap.set(timestamp, {
                            time: timestamp,
                            open: item[0],
                            high: item[1],
                            low: item[2],
                            close: item[3]
                        });
                    }
                });

                const sortedData = Array.from(timestampMap.values())
                    .sort((a, b) => a.time - b.time);

                const formattedData = [];
                let lastClose = null;
                
                if (sortedData.length > 0) {
                    const startTime = sortedData[0].time;
                    const endTime = sortedData[sortedData.length - 1].time;
                    
                    for (let t = startTime; t <= endTime; t += 60) {
                        const existingBar = sortedData.find(bar => bar.time === t);
                        
                        if (existingBar) {
                            formattedData.push(existingBar);
                            lastClose = existingBar.close;
                        } else if (lastClose !== null) {
                            formattedData.push({
                                time: t,
                                open: lastClose,
                                high: lastClose,
                                low: lastClose,
                                close: lastClose
                            });
                        }
                    }
                }
                setPrice(lastClose);
                setHistoricalData(formattedData);
            } catch (error) {
                console.error('Error fetching historical data:', error);
                fetchedRef.current = false;
            } finally {
                setIsLoading(false);
            }
        };

        fetchMetadata();
        fetchHistoricalData();
    }, [asset, pair]);

    // Effect for title updates
    useEffect(() => {
        const updateTitle = () => {
            const priceText = metadata?.pair?.includes('-USD') && price 
                ? `$${price.toFixed(2)}` 
                : price;
            document.title = `${metadata?.pair || 'Loading...'} | ${priceText} | prices.now`;
        };

        // Initial update
        updateTitle();

        // Set up interval for continuous updates
        const titleInterval = setInterval(updateTitle, 1000);

        return () => clearInterval(titleInterval);
    }, [metadata, price]);

    return (
        <>
            <Helmet>
                <title>{`${metadata?.pair || 'Loading...'} | ${metadata?.pair?.includes('-USD') && price ? '$' + price.toFixed(2) : price} | prices.now`}</title>
            </Helmet>
            <div style={{ background: '#000', color: '#DDD', minHeight: '100vh', padding: '20px'}}>
                <h1>{metadata?.pair}</h1>
                <h2>Current Price: {metadata?.pair?.includes('-USD') && price ? '$' + price.toFixed(2) : price}</h2>
                <h2>Blockchain: {metadata?.blockchain}</h2>
                <h2>Market Cap: {metadata?.supply && price ? (metadata.supply * price).toFixed(2) : 'Loading...'} {metadata?.b}</h2>
                <h2 style={{ color: '#DDD' }}>Live Chart</h2>
                {isLoading ? (
                    <div style={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        height: '400px',
                        color: '#DDD'
                    }}>
                        Loading historical data...
                    </div>
                ) : historicalData && historicalData.length > 0 ? (
                    <div style={{ width: '100%', maxWidth: '2000px' }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            padding: '10px',
                            borderRadius: '4px',
                            marginBottom: '10px'
                        }}>
                            <select
                                value={selectedTimeframe}
                                onChange={(e) => setSelectedTimeframe(e.target.value)}
                                style={{
                                    background: '#000',
                                    color: '#DDD',
                                    border: '1px solid #444',
                                    borderRadius: '4px',
                                    padding: '5px 10px',
                                    cursor: 'pointer',
                                    fontSize: '14px'
                                }}
                            >
                                <option value="1M">1 Minute</option>
                                <option value="5M">5 Minutes</option>
                                <option value="15M">15 Minutes</option>
                                <option value="30M">30 Minutes</option>
                                <option value="60M">1 Hour</option>
                            </select>
                        </div>
                        <BarChart 
                            data={historicalData}
                            timeScale={selectedTimeframe}
                            chartType="candlestick"
                            timeframe={selectedTimeframe}
                        />
                    </div>
                ) : null}
            </div>
        </>
    );
}

export default Asset;